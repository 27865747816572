import React from 'react';
import withBackground from '../../hoc/withBackground/withBackground';
import me from "../../assets/images/Home/Me.png";
import styles from './Home.module.css';

const Home = () => (
  <div className={styles["home"]}>
    <div className={styles["home__image-wrapper"]}>
      <img src={me} alt="me" />
    </div>
    <div className={styles["home__text-container"]}>
      <p className={styles["home__max-width-paragraph"]}>
        I'm a product designer driven by the belief that truly impactful design goes beyond pure aesthetics to create meaningful interactions.
        I love diving deep into how people think and behave to build digital experiences that just make sense.
        Outside of work you can catch me in the ceramics studio, running up mountains, or petting my dog.
      </p>
      <p className={styles["home__no-wrap-paragraph"]}>Email me at hi@brittanyteng.com</p>
    </div>
  </div>
);

export default withBackground(Home);