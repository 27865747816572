import Weight1 from '../../assets/images/Weight/Weight1.png';
import Weight2 from '../../assets/images/Weight/Weight2.png';
import Weight3 from '../../assets/images/Weight/Weight3.png';
import Weight4 from '../../assets/images/Weight/Weight4.png';
import Weight5 from '../../assets/images/Weight/Weight5.png';
import Weight6 from '../../assets/images/Weight/Weight6.png';
import Weight7 from '../../assets/images/Weight/Weight7.png';
import Medication1 from '../../assets/images/Medication/Medication1.png';
import Medication2 from '../../assets/images/Medication/Medication2.png';
import Medication3 from '../../assets/images/Medication/Medication3.png';
import Medication4 from '../../assets/images/Medication/Medication4.png';
import Subscription1 from '../../assets/images/Subscription/Subscription1.png';
import Subscription2 from '../../assets/images/Subscription/Subscription2.png';
import Subscription3 from '../../assets/images/Subscription/Subscription3.png';
import Subscription4 from '../../assets/images/Subscription/Subscription4.png';
import Subscription5 from '../../assets/images/Subscription/Subscription5.png';
import Subscription6 from '../../assets/images/Subscription/Subscription6.png';
import Subscription7 from '../../assets/images/Subscription/Subscription7.png';
import AppleTV1 from '../../assets/images/AppleTV/AppleTV1.png';
import AppleTV2 from '../../assets/images/AppleTV/AppleTV2.png';
import AppleTV3 from '../../assets/images/AppleTV/AppleTV3.png';
import AppleTV4 from '../../assets/images/AppleTV/AppleTV4.png';
import NikeFleece1 from '../../assets/images/NikeFleece/NikeFleece1.png';
import NikeFleece2 from '../../assets/images/NikeFleece/NikeFleece2.png';
import NikeFleece3 from '../../assets/images/NikeFleece/NikeFleece3.png';
import NikeFleece4 from '../../assets/images/NikeFleece/NikeFleece4.png';
import NikeFleece5 from '../../assets/images/NikeFleece/NikeFleece5.png';
import NikeFleece6 from '../../assets/images/NikeFleece/NikeFleece6.png';
import Daily1 from '../../assets/images/Daily/Daily1.png';

const data = {
  weight: {
    color: 'lightgreen',
    page: "Weight Tracker",
    textLeft: "Hims & Hers\nWeight Tracker",
    textRight: "Weight Loss by Hims & Hers is designed to help people achieve and maintain their weight-loss goals with personalized and affordable clinical programs that combine medication management, digital tracking tools and customized educational content.\n\nVisualizing your progress plays a big role in your success, so we’ve developed a suite of tools for users to log their day-to-day throughout their weight loss journey. Our onboarding experience educates users about our offerings, while both the weight and habit tracker help keep them consistent and motivated.",
    images: [
      { src: Weight1 },
      { src: Weight2, maxWidth: 1245 },
      { src: Weight3 },
      { src: Weight4 },
      { src: Weight5, maxWidth: 1245 },
      { src: Weight6 },
      { src: Weight7 },
    ],
    password: false,
  },
  medication: {
    color: 'orange',
    page: "Medication Reminder",
    textLeft: "Hims & Hers\nMedication Reminder",
    textRight: "Hims & Hers knows that taking the first step is always the hardest, which is why we’re dedicated to removing barriers to make healthcare more approachable. Starting a new medication can be exciting but also intimidating, so we’ve optimized a feature as simple as medication reminders to set you up for success.\n\nWe’ve personalized reminders to include titration instructions, a step-by-step video guide, and helpful links so users have everything they need when it’s time to take their medication. Users can also log their experience, side effects, and mood at each intake, so they can track patterns and evaluate whether their medication is the best fit.",
    images: [
      { src: Medication1 },
      { src: Medication2, maxWidth: 1245 },
      { src: Medication3 },
      { src: Medication4 },
    ],
    password: false,
  },
  subscription: {
    color: 'brown',
    page: "Subscription Management",
    textLeft: "Hims & Hers\nSubscription Management",
    textRight: "Hims & Hers believes that feeling good in your body & mind transforms how you show up in life. We understand the power of having access to your care where you want it, when you want it, and how you want it, which is why we’ve introduced management features so our care and treatment can align with anyone’s needs.\n\nSubscription Management has empowered over 100,000 users to flex their subscription. Not only did we rethink our current offerings like snooze and requesting refills, but we launched the ability to change your medication, dosage, and quantity to fit your lifestyle.",
    images: [
      { src: Subscription1 },
      { src: Subscription2, maxWidth: 1245 },
      { src: Subscription3 },
      { src: Subscription4 },
      { src: Subscription5 },
      { src: Subscription6 },
      { src: Subscription7 },
    ],
    password: false,
  },
  appleTV: {
    color: 'black',
    page: "Apple",
    textLeft: "Apple\nApple TV+ Press",
    textRight: "In 2019, Apple TV+ made it’s debut as the home for the world’s most creative storytellers. The Apple TV+ press site was then founded as a destination for updates and press materials on Apple Originals. Here journalists can find the latest trailers, key art, images and information around Apple TV+ — all the essentials needed to develop stories around the service.\n\nIn order for Apple TV+ to gain traction in the streaming industry, the press site needed to reflect current and future programming priorities to cater to the entertainment press. We reimagined the entire Apple TV+ press site to align with new business goals, provide journalists with a seamless experience, and complement the Apple TV+ brand.",
    images: [
      { src: AppleTV1, maxWidth: 615 },
      { src: AppleTV2 },
      { src: AppleTV3 },
      { src: AppleTV4 },
    ],
  },
  nikeFleece: {
    color: 'pink',
    page: "Nike",
    textLeft: "Nike\nNike Fleece",
    textRight: "Along side the talented teams at Industry and Instrument, we developed a creative suite to reset the Nike Fleece business in North America. This campaign powered a seasonal journey across dotcom, app, retail, and social, driving +70% year over year growth for the category.",
    images: [
      { src: NikeFleece1 },
      { src: NikeFleece2 },
      { src: NikeFleece3 },
      { src: NikeFleece4 },
      { src: NikeFleece5 },
      { src: NikeFleece6 },
    ]
  },
  daily: {
    color: 'white',
    page: "The Daily",
    textLeft: "Gretel\nThe Daily by NYT",
    textRight: "The Daily is a podcast hosted by Michael Barbaro, a reporter for The New York Times. With five million monthly unique listeners, it became the most downloaded new show in 2017 on Apple Podcasts, and now airs on more than 30 radio stations across the country.\n\nOur challenge was to communicate all of this in a succinct, memorable and non-partisan way. We developed a verbal and visual language, which threads the immediacy of the medium with the mission of the Times. Our final work ran as a national campaign, with executions that spanned out-of-home, television, radio, print and digital – all anchored by a single line: “This moment deserves to be understood.”",
    images: [
      { src: Daily1 },
    ],
  },
};

export default data;